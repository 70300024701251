<template>
  <div class="ele-body">
    <el-card shadow="never">
      <!-- 搜索表单 -->
      <el-form :model="table.where" label-width="77px" class="ele-form-search d-flex f-end"
               @keyup.enter.native="$refs.table.reload()" @submit.native.prevent>
        <el-form-item label="省:" label-width="35px" class="w-170">
          <el-select v-model="table.where.pid"  @change="handleChangeProv(table.where.pid)" placeholder='请选择省' clearable>
            <el-option v-for="option in provArr" :value="option.pid" :key="option.pid" :label="option.name"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="市:" label-width="35px" class="w-150">
          <el-select v-model="table.where.cid"  @change="handleChangeCity(table.where.cid)" placeholder='请选择市' clearable>
            <el-option v-for="option in cityArr" :value="option.cid" :key="option.cid" :label="option.name"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="县/区:" label-width="62px" class="w-200">
          <el-select v-model="table.where.aid" placeholder='请选择县/区' @change="$forceUpdate()" clearable>
            <el-option v-for="option in districtArr " :value="option.aid" :key="option.aid" :label="option.name"></el-option>
          </el-select>
        </el-form-item>
            <div class="ml-20">
              <el-button type="primary" @click="$refs.table.reload()" icon="el-icon-search" class="ele-btn-icon">搜索
              </el-button>
              <el-button @click="(table.where={category_id:1})&&$refs.table.reload()">重置</el-button>
            </div>
      </el-form>
      <!-- 数据表格 -->
      <ele-data-table ref="table" :config="table" :choose.sync="choose" height="calc(100vh - 315px)" highlight-current-row  stripe class="my-dialog-name">
        <template slot-scope="{index}">
          <el-table-column type="index" :index="index" label="编号" width="60" align="center" show-overflow-tooltip/>
          <el-table-column prop="name" label="标题"  show-overflow-tooltip min-width="240"/>
          <el-table-column prop="headimg" label=" 头像"  show-overflow-tooltip min-width="60">
            <template slot-scope="{row}">
							<el-image style="width:25px;height:25px;" :src="row.headimg" fit="cover" :preview-src-list="[row.headimg]">
								<div slot="error" class="image-slot">
									<i class="el-icon-picture-outline"></i>
								</div>
							</el-image>
            </template>
          </el-table-column>
          <el-table-column prop="username" label="昵称"  show-overflow-tooltip min-width="100"/>
          <el-table-column prop="set_area" label="地区"  show-overflow-tooltip min-width="150"/>
          <el-table-column prop="like_num" label="点赞量(个)"  show-overflow-tooltip  min-width="150px"/>
          <el-table-column prop="comment" label="评论量(个)"  show-overflow-tooltip  min-width="150px"/>
          <el-table-column prop="look" label="浏览量(次)"  show-overflow-tooltip  min-width="150px"/>
          <el-table-column label="添加时间 " show-overflow-tooltip min-width="100">
            <template slot-scope="{row}">{{ row.caretetime*1000 | toDateString }}</template>
          </el-table-column>
          <!-- <el-table-column prop="type_name" label="审核状态"  show-overflow-tooltip min-width="100"/> -->
          <el-table-column label="操作" width="200px" align="center" :resizable="false" fixed="right">
            <template slot-scope="{row}">
              <el-link @click="edit(row)" icon="el-icon-view" type="primary" :underline="false" v-if="permission.includes('sys:countycircle:edit')">查看</el-link>
              <el-link  @click="remove(row)" icon="el-icon-delete" type="danger" :underline="false" v-if="permission.includes('sys:countycircle:delete')">删除</el-link>
<!--              <el-popconfirm title="确定要删除此线圈记录吗？" @confirm="remove(row)" class="ele-action">-->
<!--                <el-link slot="reference" icon="el-icon-delete" type="danger" :underline="false" v-if="permission.includes('sys:countycircle:delete')">删除</el-link>-->
<!--              </el-popconfirm>-->
              <el-dropdown @command="command => dropClick(command,row)" v-if=" row.type==1?false:true">
                            <span class="el-dropdown-link" style="margin-left: 10px;">
                              <el-link type="primary" icon="el-icon-more" :underline="false"></el-link>
                            </span>

                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item icon="el-icon-s-check"  v-if="permission.includes('sys:driver:check_list') && row.type==1?false:true" command="through">通过</el-dropdown-item>
                  <el-dropdown-item icon="el-icon-delete-solid" v-if="permission.includes('sys:driver:delete')&& row.type==2?true:false" command="refused">拒绝</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>

            </template>
          </el-table-column>
        </template>
      </ele-data-table>
    </el-card>
    <!-- 查看线圈弹窗 -->
    <el-dialog v-dialogDrag title="查看线圈" :visible.sync="showEdit"
               @closed="editForm={}" :destroy-on-close="true" :lock-scroll="false">
      <el-form :model="editForm" ref="editForm" :rules="editRules" label-width="100px">
        <el-card shadow="never">
          <el-form-item label="标题：" prop="name">
            <el-input type="textarea" :rows="5"  placeholder="请输入内容" v-model="editForm.name" disabled> </el-input>
          </el-form-item>
          <el-form-item  label="显示图片：" prop="images"  show-overflow-tooltip >
            <div v-for="(option, index) in editForm.imgList" :key="index" style="width:100px;height:100px;margin-right:10px;float:left;margin-bottom:10px">
              <el-image v-if="option.type==1"  style="width: 100px; height: 100px;float: left" class="mt-10 mr-10" :src="option.src" fit="cover" :preview-src-list="[editForm.imgArr]"></el-image>
              <video v-if="option.type==2"  style="width: 100px; height: 100px;float: left"  :src="option.src"  controls="controls">您的浏览器不支持视频播放</video>
            </div>
          </el-form-item>
        </el-card>
        <el-card shadow="never">
          <el-form-item style="display: flex;flex-direction: column;">
            <div v-if="editForm.like_num!=0"><img style="width: 20px; vertical-align: sub; margin-right: 10px;" src="../../../assets/zan.png" alt="">{{editForm.like_num}}人</div>
					<div style="border-bottom: dashed 1px #000000;font-size: 16px;font-weight: bold;">评论详情</div>
            <div>
							<!-- 评论 -->
							<div v-for="(option, index) in editForm.comment_list" :key="index" class="commentBox">
								<div class="commentListBox">
                  
                  <div style="float: right; margin-top: 10px;" class="ele-action">
                    <el-link slot="reference" icon="el-icon-delete" type="danger" @click="removeSelf(option)" :underline="false">删除</el-link>
                  </div>
                  
                  
              
                  
                  
                  
									<div class="commentList">
										<div class="commentHeading">
											<img class="imgSize" :src="option.user_info.headimg" />
										</div>
										<div class="commentDetail">
											<div>
												<span class="commentName">{{option.user_info.name}}</span>
												<span class="contentTime">{{option.createtime*1000 | toDateString}}</span>
											</div>
											<div class="commentContent">评论内容：{{option.content}}</div>
											<div v-for="(option1, index) in option.comment_list" :key="index" class="replyList">
												<div class="commentHeading1">
													<img class="imgSize" :src="option1.user_info.headimg" />
												</div>
												<div class="commentDetail">
													<div>
														<span class="commentName">{{option1.user_info.name}}</span>
														<span class="contentTime">{{option1.createtime*1000 | toDateString}}</span>
													</div>
													<div class="commentContent">回复内容：{{option1.content}}</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div v-if="editForm.comment_list==''" style="height: 50px;padding-top: 20px;">暂无评论</div>
          </el-form-item>
        </el-card>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "SysCountyCircle",
  data() {
    return {
      table: {url: '/countycircle/get_index_data', where: {category_id:1}},  // 表格配置
      provArr:[],
      cityArr:[],
      districtArr:[],
      choose: [],  // 表格选中数据
      showEdit: false,  // 是否显示表单弹窗
      editForm: {},  // 表单数据
      non:'block',
      non1:'none',
      editRules: {  // 表单验证规则
      },
    }
  },
  created(){
    this.$http.get('/common/province_list').then(res=>{
      let data=JSON.parse(res.data)
      this.provArr=data
    })
  },
computed: {
    ...mapGetters(["permission"]),
},
  mounted() {
  },
  methods: {
    removeSelf(row){
      //console.log('56666666666666655555555555555555uuuu',row);
      if((row.comment_list.length == 0) && (!row.id) ){
        return this.$message.error('暂无评论');
      }
      let bcomment_id= row.comment_list.length == 0?row.id:row.comment_list[0].bcomment_id;
      
      this.$confirm('确定要删除此线圈评论吗?', '提示', {type: 'warning'}).then(() => {
        const loading = this.$loading({lock: true});
        this.$http.post('/Countycircle/delCountyCirceleComment', {id:bcomment_id}).then(res => {
          loading.close();
          if (res.data.code === 0) {
            this.$message({type: 'success', message: res.data.msg});
            this.$refs.table.reload();
            this.showEdit = false;
          } else {
            this.$message.error(res.data.msg);
          }
        }).catch(e => {
          loading.close();
          this.$message.error(e.message);
        });
      });
      
     
     
      
      
      
      
    },
    handleError(){
      this.non='none'
    },

    /**
     *选择省
     **/
    handleChangeProv(e){
      /** 获取被选省份的pid**/
      let pid=''
      this.provArr.forEach(function(item){
        if(item.pid==e){
          pid=item.pid
        }
      })
      /** 根据被选省份的pid获取省市下面的市**/
      this.$http.post('/common/city_list',{pid:pid}).then(res=>{
        let data=JSON.parse(res.data)
        this.cityArr=data
        /** 选择省份清空市县**/
        this.table.where.cid=''
        this.table.where.area_id=''
      })
    },
    /**
     *选择市
     **/
    handleChangeCity(e){
			if(e){
      /** 获取被选市的cid**/
      let cid=''
      this.cityArr.forEach(function(item){
        if(item.cid==e){
          cid=item.cid
        }
      })
      /** 根据被选市的cid获取市下面的县**/
      this.$http.post('/common/area_list',{cid:cid}).then(res=>{
        let data=JSON.parse(res.data)
        this.districtArr=data
        /** 选择省份清空县**/
        this.table.where.aid=''
      })
			}else{
				this.table.where.pid = ''
				this.table.where.aid = ''
			}
    },
    dropClick(command,row){
      if(command==='through'){//通过
        this.handleThrough(row)
      }else if(command==='refused'){//拒绝
        this.handleRefused(row)
      }
    },
    //通过
    handleThrough(row){
      this.$confirm('确认通过线圈审核？',"提示").then(()=>{
        const loading = this.$loading({lock: true});
        this.$http.post('/Countycircle/set_country_type',{id:row.id,type:1}).then(res=>{
          loading.close()
          if(res.data.code==0){
            this.$message({type:'success',message:res.data.msg});
            this.$refs.table.reload()
          }else{
            this.$message.error(res.data.msg)
          }
        }).catch(e=>{
          loading.close()
          this.$message.error(e.message)
        })
      })
    },
    //拒绝
    handleRefused(row){
      this.$confirm('确认不通过线圈审核？',"提示").then(()=>{
        const loading = this.$loading({lock: true});
        this.$http.post('/Countycircle/set_country_type',{id:row.id,type:3}).then(res=>{
          loading.close()
          if(res.data.code==0){
            this.$message({type:'success',message:res.data.msg});
            this.$refs.table.reload()
          }else{
            this.$message.error(res.data.msg)
          }
        }).catch(e=>{
          loading.close()
          this.$message.error(e.message)
        })
      })
    },


    /**
     *修改省
     **/
    handleChangeProv1(e){
      /** 获取被选省份的pid**/
      var pid=''
      this.provArr.forEach(function(item){
        if(item.name==e){
          pid=item.pid
        }
      })
      /** 根据被选省份的pid获取省市下面的市**/
      this.$http.post('/common/city_list',{pid:pid}).then(res=>{
        var data=JSON.parse(res.data)
        this.cityArr=data
        /** 选择省份清空市县**/
        this.editForm.cname=''
        this.editForm.aname=''
      })
    },
    /**
     *修改市
     **/
    handleChangeCity1(e){
			if(e){
      /** 获取被选市的cid**/
      var cid=''
      this.cityArr.forEach(function(item){
        if(item.name==e){
          cid=item.cid
        }
      })
      /** 根据被选市的cid获取市下面的县**/
      this.$http.post('/common/area_list',{cid:cid}).then(res=>{
        var data=JSON.parse(res.data)
        this.districtArr=data
        /** 选择省份清空县**/
        this.editForm.aname=''
      })
			}else{
				this.editForm.pname = ''
				this.editForm.aname = ''
			}
    },
    /**
     * 显示编辑
     */
    edit(row) {
      let that=this;
      this.$http.get('/countycircle/info?id=' + row.id).then(res => {
        if (res.data.code === 0) {
          res.data.data.imgArr=res.data.data.images.split(',')
          res.data.data.imgList=[]
          res.data.data.imgArr.forEach(function(item){
           let obj={id:'',src:''}
                  obj.type=that.$util.getFileType(item);
                   obj.src=item
           
            res.data.data.imgList.push(obj)
            //console.log(res.data.data.imgList)
          })
          this.showEdit = true;
          this.editForm=res.data.data
          //console.log('edit7777777777777777777',this.editForm)


        } else {
          this.$message.error(res.data.msg);
        }
      }).catch(e => {
        this.$message.error(e.message);
      });
    },
    /**
     * 保存编辑
     */
    save() {
      this.$refs['editForm'].validate((valid) => {
        if (valid) {
          const loading = this.$loading({lock: true});
          this.$http.post('/countycircle/edit', this.editForm).then(res => {
            loading.close();
            if (res.data.code === 0) {
              this.showEdit = false;
              this.$message({type: 'success', message: res.data.msg});
              this.$refs.table.reload();
            } else {
              this.$message.error(res.data.msg);
            }
          }).catch(e => {
            loading.close();
            this.$message.error(e.message);
          });
        } else {
          return false;
        }
      });
    },
    /**
     * 刪除(批量刪除)
     */
    remove(row) {
      this.$confirm('确定要删除此线圈记录吗?', '提示', {type: 'warning'}).then(() => {
        const loading = this.$loading({lock: true});
        this.$http.post('/countycircle/county_circle_del', {id: row.id}).then(res => {
          loading.close();
          if (res.data.code === 0) {
            this.$message({type: 'success', message: res.data.msg});
            this.$refs.table.reload();
          } else {
            this.$message.error(res.data.msg);
          }
        }).catch(e => {
          loading.close();
          this.$message.error(e.message);
        });
      })

    },
  }
}
</script>

<style scoped>
.ele-block >>> .el-upload, .ele-block >>> .el-upload-dragger {
  width: 100%;
}
/* 评论 */
.commentBox{
	padding: 15px 15px 0 15px;
}
.commentBox img{
	border-radius: 50%;
	width: 100%;
	height: 100%;
}
.commentTotal{
	font-size: 16px;
	color: #333;
}
.commentList{
	display: flex;
	padding: 10px 0;
	border-bottom: 1px solid #eee;
}
.commentDetail{
	margin-left: 15px;
}
.replyList{
	display: flex;
	margin-top: 10px;
}
.commentHeading{
	height: 32px;
	width: 32px;
}
.commentHeading1{
	height: 20px;
	width: 20px;
}
.commentName{
	font-size: 13px;
	color: #333;
	display: inline-block;
	margin-right: 10px;
}
.contentTime{
	color: #999;
}
.commentContent{
	font-size: 14px;
	color: #333;
	margin: 8px 0;
}
</style>